import React from 'react'
import CabaretLayout from '../../../components/cabaretlayout'
import CabaretInfosNavigation from '../../../components/Infos/CabaretInfos/CabaretInfosNavigations'

const CabaretInfosPrix = ({ noTitle }) => (
    <CabaretLayout>
        <div className='cabaret-infos'>

            <CabaretInfosNavigation />

            <h1>Les prix</h1>

            <div className='cabaret-infos-inner'>

                <h3 className='font-bold'>Remis lors de la Grande Finale du 29 mars 2025</h3>

                {/* PRIX DU JURY */}
                <div className='cabaret-infos-inner-list'>
                    <h2>Prix du Jury</h2>
                    <ul>
                        <li><h3>Bourse de 10000$ offerte par Sirius XM</h3></li>
                        <li><h3>Prestation rémunérée dans l'une des prochaines éditions du Festif! de Baie-St-Paul</h3></li>
                        <li><h3>Solotech offre 2000$ de crédit en location d’équipement</h3></li>
                        <li><h3>CHYZ offre au projet gagnant le statut d’artiste du mois : rotation forte de sa musique sur les ondes, une émission spéciale de 90 minutes qui lui sera consacrée et enregistrement et diffusion d’une session acoustique en studio</h3></li>
                        <li><h3>Lepointdevente.com offre au projet gagnant un forfait publicitaire d’une valeur de 750$ sur leur plateforme, applicable à un ou plusieurs spectacles</h3></li>
                    </ul>
                </div>

                {/* PRIX DU PUBLIC */}
                <div className='cabaret-infos-inner-list'>
                    <h2>Prix du Public</h2>
                    <ul>
                        <li><h3>Bourse de 3000$ offerte par Cogeco</h3></li>
                        <li><h3>Prestation dans l'une des prochaines éditions du Festif! de Baie-Saint-Paul</h3></li>
                        <li><h3>1000$ de promo et entrevue-prestation sur les ondes de CKRL</h3></li>
                        <li><h3>Séance photo professionnelle offerte par Écoutedonc.ca</h3></li>
                    </ul>
                </div>

                {/* PRIX AUX QUATRE PROJETS FINALISTES */}
                <div className='cabaret-infos-inner-list'>
                    <div className='encadre'>
                        <h2>Prix aux quatre projets finalistes</h2>
                        <ul>
                            <li><h3>Abonnement annuel au Phoque Off Pro</h3></li>
                            <li><h3>Couverture nationale de la part de Exclaim!</h3></li>
                            <li><h3>Temps de gestion des droits d’auteur.e avec Sébastien Charest</h3></li>
                        </ul>
                    </div>
                </div>

                {/* PRIX DU PORTE-PAROLE */}
                <div className='cabaret-infos-inner-list'>
                    <h2>Prix du Porte-Parole</h2>
                    <ul>
                        <li><h3>Première partie d’un spectacle de commend debord lors de leur prochaine tournée</h3></li>
                    </ul>
                </div>

                {/* PRIX LE PANTOUM */}
                <div className='cabaret-infos-inner-list'>
                <a href="https://www.lepantoum.com/" target="_blank" rel="noreferrer"><h2>Prix Le Pantoum</h2></a>
                    <ul>
                        <li><h3>Fin de semaine d’enregistrement au studio Le Pantoum pour réalisation de projet ou exploration</h3></li>
                    </ul>
                </div>

                {/* PRIX LE PANTOUM */}
                <div className='cabaret-infos-inner-list'>
                    <a href="https://www.telequebec.tv/" target="_blank" rel="noreferrer"><h2>Prix coup de coeur Télé-Québec</h2></a>
                    <ul>
                        <li><h3>Session musicale réalisée par la Fabrique culturelle et une mise en valeur sur leurs plateformes de diffusion</h3></li>
                    </ul>
                </div>

                {/* PRIX FESTIVAL INTERNATIONAL DE LA CHANSON DE GRANBY */}
                <div className='cabaret-infos-inner-list'>
                    <a href="https://ficg.qc.ca/" target="_blank" rel="noreferrer"><h2>Prix Festival international de la Chanson de Granby</h2></a>
                    <ul>
                        <li><h3>Spectacle-vitrine dans le cadre de l’édition 2025 du Festival International de la Chanson de Granby</h3></li>
                    </ul>
                </div>

                {/* PRIX DOMAINE FORGET */}
                <div className='cabaret-infos-inner-list'>
                    <a href="https://www.domaineforget.com/" target="_blank" rel="noreferrer"><h2>Prix Domaine Forget</h2></a>
                    <ul>
                        <li><h3>Résidence de création de 3 nuits sur les lieux du domaine avec local de répétition</h3></li>
                    </ul>
                </div>

                {/* PRIX LE PHOQUE OFF */}
                <div className='cabaret-infos-inner-list'>
                    <a href="https://phoqueoff.com/" target="_blank" rel="noreferrer"><h2>Prix Le Phoque Off</h2></a>
                    <ul>
                        <li><h3>Vitrine dans un lieu de diffusion de Québec lors de l’édition 2026 de l'événement</h3></li>
                    </ul>
                </div>

                {/* PRIX CISM */}
                <div className='cabaret-infos-inner-list'>
                    <a href="https://cism893.ca/" target="_blank" rel="noreferrer"><h2>Prix CISM</h2></a>
                    <ul>
                        <li><h3>2500$ de promo sur les ondes.</h3></li>
                        <li><h3>Participation et prestation à l’émission Session live à CISM</h3></li>
                    </ul>
                </div>

                {/* PRIX Mixbus */}
                <div className='cabaret-infos-inner-list'>
                    <a href="https://www.mixbusstudio.com" target="_blank" rel="noreferrer"><h2>Prix Mixbus</h2></a>
                    <ul>
                        <li><h3>Fin de semaine au nouveau studio du Mixbus pour exploration et création musicale</h3></li>
                    </ul>
                </div>

                {/* PRIX Greg Kitzler | La Shed relations de presse */}
                <div className='cabaret-infos-inner-list'>
                    <a href="https://www.lashoebox-rp.com/" target="_blank" rel="noreferrer"><h2>Prix Greg Kitzler | La Shed relations de presse</h2></a>
                    <ul>
                        <li><h3>Campagne de relations de presse pour le lancement d'un EP / album</h3></li>
                    </ul>
                </div>

                {/* PRIX L’Ampli de Québec */}
                <div className='cabaret-infos-inner-list'>
                    <a href="https://www.amplidequebec.com" target="_blank" rel="noreferrer"><h2>Prix L’Ampli de Québec</h2></a>
                    <ul>
                        <li><h3>4 jours en résidence de création dans un local de répétition</h3></li>
                        <li><h3>1 journée en studio d’enregistrement au sortir de la résidence incluant le technicien de son</h3></li>
                    </ul>
                </div>

                {/* PRESTATION RÉMUNÉRÉE DANS LES PROGRAMMATIONS SUIVANTES */}
                <div className='cabaret-infos-inner-list'>
                    <h2>Prestation rémunérée dans les programmations suivantes:</h2>
                    <ul>
                        <a href="https://festivalenchanson.com/" target='_blank' rel="noreferrer"><li><h3>Prix Festival de la Chanson de Petite Vallée</h3></li></a>
                        <a href="https://chansontadoussac.com/" target='_blank' rel="noreferrer"><li><h3>Prix Festival de la Chanson de Tadoussac</h3></li></a>
                        <a href="https://www.vieuxbureaudeposte.com/" target='_blank' rel="noreferrer"><li><h3>Prix Vieux Bureau de Poste</h3></li></a>
                        <a href="https://www.lezaricot.com/" target='_blank' rel="noreferrer"><li><h3>Prix Le Zaricot</h3></li></a>
                        <a href="https://minotaure.ca/" target='_blank' rel="noreferrer"><li><h3>Prix Le Minotaure</h3></li></a>
                        <a href="https://www.aubergefestive.com/" target='_blank' rel="noreferrer"><li><h3>Prix Auberge Festive Sea Shack</h3></li></a>
                        <a href="https://www.promenadewellington.com/fr/evenement/cabane-panache-2022/" target='_blank' rel="noreferrer"><li><h3>Prix Cabane Panache (Édition 2026)</h3></li></a>
                        <a href="https://aubergedesbalcons.com/le-bistro/" target='_blank' rel="noreferrer"><li><h3>Prix Bistro des Balcons</h3></li></a>
                        <a href="https://www.quebecoff.org" target='_blank' rel="noreferrer"><li><h3>Prix Festival Off</h3></li></a>
                        <a href="https://www.festivalcolline.com" target='_blank' rel="noreferrer"><li><h3>Prix Festival Colline</h3></li></a>
                        <a href="https://popmontreal.com/fr" target='_blank' rel="noreferrer"><li><h3>Prix POP Montréal</h3></li></a>
                        <a href="https://festivaldelapoutine.com" target='_blank' rel="noreferrer"><li><h3>Prix Festival de la Poutine</h3></li></a>
                        <a href="https://www.strochxp.com" target='_blank' rel="noreferrer"><li><h3>Prix St-Roch XP</h3></li></a>
                    </ul>
                </div>
            </div>

        </div>
    </CabaretLayout>
)

export default CabaretInfosPrix
