/* eslint-disable quotes */
class MetaInfo {
  constructor (title, description, keywords, image, url) {
    this.title = title
    this.description = description
    this.keywords = keywords
    this.image = image
    this.url = url
  }
}
const cabaretMeta = new MetaInfo('Cabaret Festif! - 14e édition',
  'Le Cabaret Festif!, volet concours du festival Le Festif! est né en 2011, suite à la deuxième édition du festival.',
  'cabaret, festif, baie-saint-paul, relève, musique, spectacle',
  'https://images.prismic.io/le-festif-ca/Zygkk68jQArT0JW3_og_cabaret.png',
  'https://lefestif.ca/cabaret')

class CabaretArtist {
  constructor (name,
    slug,
    date,
    facebook,
    instagram,
    spotify,
    apple,
    website,
    youtube,
    tiktok,
    youtubeVideoId,
    style) {
    this.name = name
    this.slug = slug
    this.date = date
    this.facebook = facebook
    this.instagram = instagram
    this.spotify = spotify
    this.apple = apple
    this.website = website
    this.youtube = youtube
    this.tiktok = tiktok
    this.youtubeVideoId = youtubeVideoId
    this.style = style

    this.getImageLocalPath = `cabaret/artists/${this.slug}.jpg`
  }
}

const janvier = '25 janvier 2025'
const fevrier = '15 février 2025'
const mars = '8 mars 2025'
const allCabaretCandidats =
[
  new CabaretArtist(
    'easy tiger',
    'easytiger',
    janvier,
    'https://www.facebook.com/easytigermtl',
    'https://www.instagram.com/easy.tiger.theband/',
    'https://open.spotify.com/intl-fr/artist/7swlwLCYgIwOx5eJJhMoJb?si=_JvfdX-hRYmp5wNoP9DF9A',
    'https://music.apple.com/ca/artist/easy-tiger/1620167541?l=fr-CA',
    undefined,
    'https://www.youtube.com/channel/UC5_BBb8FOo6kwac3T23RY4Q',
    'http://www.tiktok.com/@easy.tiger.theband',
    'OXgCW5iI4EM',
    'rock indie pop'
  ),
  new CabaretArtist(
    'Érika Zarya',
    'erikazarya',
    janvier,
    'https://www.facebook.com/ErikaZarya/?locale=fr_CA',
    'https://www.instagram.com/erika.zarya/',
    'https://open.spotify.com/intl-fr/artist/0Liks0cOyEaCRwpc25SFan',
    'https://music.apple.com/ca/artist/%C3%A9rika-zarya/1547634247?l=fr-CA',
    undefined,
    'https://www.youtube.com/channel/UC8UWq6Ve2LL8qrBLe3wKuVQ',
    undefined,
    'xU8UpX7B7r4',
    'rnb hip-hop'
  ),
  new CabaretArtist(
    'Renard Blanc',
    'renardblanc',
    janvier,
    'https://www.facebook.com/lacollectivitedurenardblanc',
    'https://www.instagram.com/renardblanc.musique/',
    'https://open.spotify.com/artist/5E6xkVcyWbSpV26oeO5UVP?si=rg55lxE0RpaE98g-dKts6Q',
    'https://music.apple.com/ca/artist/renard-blanc/1051760109?l=fr-CA',
    'https://renardblanc.bandcamp.com/music',
    'https://www.youtube.com/@renardblanc2772',
    'https://www.tiktok.com/@renardblanc.musique?is_from_webapp=1&sender_device=pc',
    'Gxs3n2_OEe0',
    'Rock en spirale'
  ),
  new CabaretArtist(
    'Sara et Rebecca',
    'saraetrebecca',
    janvier,
    'https://www.facebook.com/saraetrebecca',
    'https://www.instagram.com/saraetrebecca/',
    'https://open.spotify.com/intl-fr/artist/47hDWZwrDwVIOW9WkXAxZZ?si=nxhHVJXOR76mTl0QGsFG1w',
    'https://music.apple.com/ca/artist/sara-et-rebecca/1679831215?l=fr-CA',
    undefined,
    'https://www.youtube.com/@saraetrebecca',
    'https://www.tiktok.com/@sarapisreb?_t=8h5VzNxh8JK&_r=1',
    'Sor8lmZFQ8Y',
    ' Instrumental latin psychédélique'
  ),
  new CabaretArtist(
    'Gabriella Olivo',
    'gabriellaolivo',
    fevrier,
    'https://www.facebook.com/ggabriellaolivo/',
    'https://www.instagram.com/ggabriellaolivo',
    'https://open.spotify.com/intl-fr/artist/16TtQYVADdkb4Ay75wCVhO?si=EQPpWOzzQ0yLOiMkcivagA',
    'https://music.apple.com/ca/artist/gabriella-olivo/1622060839?l=fr-CA',
    undefined,
    'https://www.youtube.com/@gabriellaolivo',
    'https://www.tiktok.com/@gggabriellaolivo',
    'zewSNwa9y2o',
    'dreamy pop'
  ),
  new CabaretArtist(
    'Les Reynette',
    'lesreynettes',
    fevrier,
    'https://www.facebook.com/people/Les-Reynette/61560182791614/?_rdr',
    'https://www.instagram.com/lesreynette/',
    'https://open.spotify.com/intl-fr/artist/46tQgTvD5kKdv8TKBLQXE2',
    'https://music.apple.com/ca/artist/les-reynette/1759620494?l=fr-CA',
    undefined,
    'https://www.youtube.com/channel/UCbtCZvYZ9EDQ-6myIO7YiXQ',
    undefined,
    'haZPCl8ZMQU',
    'Pop-Rock'
  ),
  new CabaretArtist(
    'Vaëlle',
    'vaelle',
    fevrier,
    'https://www.facebook.com/hellovaelle/',
    'https://www.instagram.com/hellovaelle/',
    undefined,
    undefined,
    'https://www.hellovaelle.com',
    'https://www.youtube.com/vaelle',
    'https://www.tiktok.com/@hellovaelle',
    'bT5DCDBF7NQ',
    'Synth Wave/Indie Pop'
  ),
  new CabaretArtist(
    'hôte',
    'hote',
    fevrier,
    'https://www.facebook.com/musiquehote/',
    'https://www.instagram.com/hote.hote.hote',
    'https://open.spotify.com/intl-fr/artist/2TN2ejUvw00uSbRc6GV6ev?si=2uKIfoa5QiaTt0SslvPWAg',
    'https://music.apple.com/ca/artist/h%C3%B4te/1510619988?l=fr-CA',
    'https://hote-hote-hote.com',
    'https://www.youtube.com/channel/UCLd0cCztjJOxdSTQdAMdZ-Q',
    undefined,
    'Tt49qnCJFrg',
    'Freak-folk/Indie-rock'
  ),
  new CabaretArtist(
    'Valse Fréquence',
    'valsefrequence',
    mars,
    'https://www.facebook.com/valsefrequence/?locale=fr_CA',
    'https://www.instagram.com/valsefrequence/',
    'https://open.spotify.com/intl-fr/artist/2Pnfk9ns1EzESXQoP8hcSC?autoplay=true',
    'https://music.apple.com/ca/artist/valse-fr%C3%A9quence/1328135506?l=fr-CA',
    undefined,
    'https://www.youtube.com/channel/UCnzDuvsLrhfNktOfzi2B11Q',
    'https://www.tiktok.com/@valsefrequence',
    'd3vbO2FO4xc',
    'Indie/Rock'
  ),
  new CabaretArtist(
    'Jack Layne',
    'jacklayne',
    mars,
    'https://www.facebook.com/Lepaslourd',
    'https://www.instagram.com/lepaslourd/',
    'https://open.spotify.com/intl-fr/artist/6ewOvMyMCAVPqO4zsNdQQX?si=90YqwFaIRfOQHhzx1PMtHQ',
    'https://music.apple.com/ca/artist/jack-layne/1315348244?l=fr-CA',
    undefined,
    'https://www.youtube.com/channel/UCv04hm1-gcTlezRJqoFY6xQ',
    'https://www.tiktok.com/@lepaslourd?_t=ZM-8sqhoVCAyMo&_r=1',
    'Q_cKGBPSLfI',
    'Hip-Hop Alternatif'
  ),
  new CabaretArtist(
    'Fleur de Peau',
    'fleurdepeau',
    mars,
    'https://www.facebook.com/fleurdepeaumusique',
    'https://www.instagram.com/fleurdepeaumusic/',
    'https://open.spotify.com/intl-fr/artist/6EoKem2HNXbpL23782Daoi?si=Wq1_8ganSoSN3YmAPsqywg',
    'https://music.apple.com/ca/artist/fleur-de-peau/1451735194?l=fr-CA',
    'https://www.fleurdepeaumusique.com',
    'https://www.youtube.com/channel/UCFvyQKe-QXRNzrLxaz9QQ2w',
    'https://www.tiktok.com/@fleur.de.peau.music',
    'CxtUXpcbC84',
    'Électro Pop'
  ),
  new CabaretArtist(
    'Crachat',
    'crachat',
    mars,
    'https://www.facebook.com/crachatQC/?locale=fr_CA',
    'https://www.instagram.com/crachat.band/',
    'https://open.spotify.com/intl-fr/artist/6aJ9gcd1UN9hOkK6L4Pq5A?si=iq0GEXmwSui1aPeFqZi2Mg',
    'https://music.apple.com/ca/artist/crachat/1732755402?l=fr-CA',
    undefined,
    'https://www.youtube.com/@crachat',
    'https://www.tiktok.com/@girlsinthepit',
    'P_y1cKqQlgs',
    'Punk rock'
  )
]

function getCabaretCandidatBySlug (slug) {
  for (let i = 0; i < allCabaretCandidats.length; i++) {
    if (allCabaretCandidats[i].slug === slug) {
      return allCabaretCandidats[i]
    }
  }

  return undefined
}

const cabaretTicketUrl = "https://lepointdevente.com/billets/cabaretfestif2025"

module.exports = {
  getCabaretCandidatBySlug,
  allCabaretCandidats,
  cabaretMeta,
  cabaretTicketUrl
}
