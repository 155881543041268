import React from 'react'
import CabaretLayout from '../../components/cabaretlayout'
import { cabaretTicketUrl } from '../../data/cabaret-data'

const CabaretBilletterie = () => (
    <CabaretLayout>
        <div className='cabaret-billetterie'>

            <div className='cabaret-page-title'>
                <h1>Billetterie</h1>
            </div>

            <h3>Si tu es une curieuse ou un curieux, procure-toi la Passe-Curieux qui te donnera accès à une tonne de privilèges, dont l’accès aux quatres soirées de spectacles à un prix avantageux, en plus d’une consommation sur place. Autre privilège très important; seules les personnes détenant une Passe-Curieux auront le droit de vote du quatrième projet pouvant accéder à La Grande Finale! Les billets sont aussi disponibles pour chaque soirée individuellement.</h3>
            <h3>L’admission est gratuite pour les 10 ans et moins assis sur leur parent.</h3>

            {/* BILLETS */}
            <div className="flex flex-wrap w-full mt-8">

                {/* JANVIER */}
                <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
                    <div className="bg-white rounded-3xl">
                        <div className="bg-purple-cabaret rounded-t-3xl px-1 py-6 text-white">
                            <h1 className="leading-none">25 janvier</h1>
                        </div>
                        <div className="text-purple-cabaret py-4 px-6">
                            <h3><a href="/cabaret/lescandidats/easytiger" className='hover:underline'>easy tiger</a></h3>
                            <h3><a href="/cabaret/lescandidats/erikazarya" className='hover:underline'>Érika Zarya</a></h3>
                            <h3><a href="/cabaret/lescandidats/renardblanc" className='hover:underline'>Renard Blanc</a></h3>
                            <h3><a href="/cabaret/lescandidats/saraetrebecca" className='hover:underline'>Sara et Rebecca</a></h3>
                            <div className='cabaret-billetterie !m-0 p-0'>
                                <div className='flex justify-center items-center'>
                                    <form action={cabaretTicketUrl} target='_blank'>
                                        <button type='submit'>Acheter</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* FÉVRIER */}
                <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
                    <div className="bg-white rounded-3xl">
                        <div className="bg-purple-cabaret rounded-t-3xl px-1 py-6 text-white">
                            <h1 className="leading-none">15 février</h1>
                        </div>
                        <div className="text-purple-cabaret py-4 px-6">
                            <h3><a href="/cabaret/lescandidats/gabriellaolivo" className='hover:underline'>Gabriella Olivo</a></h3>
                            <h3><a href="/cabaret/lescandidats/lesreynettes" className='hover:underline'>Les Reynette</a></h3>
                            <h3><a href="/cabaret/lescandidats/vaelle" className='hover:underline'>Vaëlle</a></h3>
                            <h3><a href="/cabaret/lescandidats/hote" className='hover:underline'>hôte</a></h3>
                            <div className='cabaret-billetterie !m-0 p-0'>
                                <div className='flex justify-center items-center'>
                                    <form action={cabaretTicketUrl} target='_blank'>
                                        <button type='submit'>Acheter</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* 9 MARS */}
                <div className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
                    <div className="bg-white rounded-3xl">
                        <div className="bg-purple-cabaret rounded-t-3xl px-1 py-6 text-white">
                            <h1 className="leading-none">8 mars</h1>
                        </div>
                        <div className="text-purple-cabaret py-4 px-6">
                            <h3><a href="/cabaret/lescandidats/valsefrequence" className='hover:underline'>Valse Fréquence</a></h3>
                            <h3><a href="/cabaret/lescandidats/jacklayne" className='hover:underline'>Jack Layne</a></h3>
                            <h3><a href="/cabaret/lescandidats/fleurdepeau" className='hover:underline'>Fleur de Peau</a></h3>
                            <h3><a href="/cabaret/lescandidats/crachat" className='hover:underline'>Crachat</a></h3>
                            <div className='cabaret-billetterie !m-0 p-0'>
                                <div className='flex justify-center items-center'>
                                    <form action={cabaretTicketUrl} target='_blank'>
                                        <button type='submit'>Acheter</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* La Grande Finale */}
                <div className="md:w-1/2 w-full text-center px-4 mt-8">
                    <div className="bg-white rounded-3xl">
                        <div className="bg-purple-cabaret rounded-t-3xl px-1 py-6 text-white">
                            <h1 className="leading-none">La Grande Finale</h1>
                        </div>
                        <div className="text-purple-cabaret px-6">
                            <h3>29 mars 2025</h3>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <div className='cabaret-billetterie !m-0 p-0'>
                                <div className='flex justify-center items-center'>
                                    <form action={cabaretTicketUrl} target='_blank'>
                                        <button type='submit'>Acheter</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* PASSE CURIEUX */}
                <div className="md:w-1/2 w-full text-center px-4 md:my-0 !mt-8">
                    <div className="bg-white rounded-3xl">
                        <div className="bg-purple-cabaret rounded-t-3xl px-1 py-6 text-white">
                            <h1 className="leading-none">Passe-Curieux</h1>
                        </div>
                        <h3>Accès aux quatres soirées à prix avantageux</h3>
                        <h3>Une consommation sur place</h3>
                        <h3>Droit de vote du quatrième projet pouvant accéder à La Grande Finale!</h3>
                        <div className='cabaret-billetterie !m-0 p-0'>
                            <div className='flex justify-center items-center'>
                                <form action={cabaretTicketUrl} target='_blank'>
                                    <button type='submit'>Acheter</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </CabaretLayout>
)

export default CabaretBilletterie
