import React from 'react'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

const CabaretSponsor = ({ sponsor }) => {
  return (
    <div className=''>
      <a href={sponsor.link.url} target="_blank" rel="noreferrer noopener" className="">
        {sponsor.image?.gatsbyImageData
          ? <GatsbyImage className="max-w-[100px] max-h-[50px]" image={sponsor.image.gatsbyImageData} alt={sponsor.image.alt ?? sponsor.name.text ?? 'Partenaire'} />
          : sponsor.name.text }
      </a>
    </div>
  )
}

const CabaretSponsorCategory = ({ data, category }) => {
  return (
    <div className="flex flex-wrap justify-center items-center">
      {data.allPrismicCabaretsponsor.edges
        .filter(sponsor => sponsor.node.data.category === category)
        .sort((a, b) => {
          const priorityA = a.node.data.priority
          const priorityB = b.node.data.priority
          if (priorityA === undefined || priorityA === null || priorityB === undefined || priorityB === null) {
            return Number.MAX_VALUE
          }

          return priorityA - priorityB
        })
        .map((prismicSponsor) => (
          <CabaretSponsor key={prismicSponsor.node.data.name.text} sponsor={prismicSponsor.node.data} />
        ))}
    </div>
  )
}

const CabaretSponsors = ({ data }) => {
  return (
    <div className="cabaret-sponsors !relative lg:px-24 md:px-6 px-0 mb-12 z-10 md:max-w-[1415px]">

        <div className="flex justify-center">

            {/* COLLABORATEUR */}
            <div className="flex flex-col items-center md:pl-8 pl-0">
                <p className="uppercase font-poppins font-light text-black mt-8 mb-2">Présenté par</p>
                <a href="https://www.siriusxm.ca/fr/" target="_blank" rel="noreferrer noopener" className="px-2">
                    <StaticImage className="max-w-[200px] max-h-[100px]" src="../images/cabaret/sponsors/siriusxm.png" alt="SiriusXM" />
                </a>
            </div>

            {/* PRÉSENTATEUR */}
            <div className="flex flex-col items-center pr-8">
                <p className="uppercase font-poppins font-light text-black mt-8 mb-2">En collaboration avec</p>
                <div className="flex">
                  <a href="https://www.cogeco.ca/fr" target="_blank" rel="noreferrer noopener" className="px-2">
                    <StaticImage className="max-w-[200px] max-h-[100px]" src="../images/cabaret/sponsors/cogeco.png" alt="Cogeco" />
                  </a>
                  <a href="https://www.hydroquebec.com/" target="_blank" rel="noreferrer noopener" className="px-2">
                      <StaticImage className="max-w-[200px] max-h-[100px]" src="../images/cabaret/sponsors/hydroquebec.png" alt="Hydro-Québec" />
                  </a>
                </div>
            </div>

        </div>

        <CabaretSponsorCategory data={data} category="ultraplatine" />
        <CabaretSponsorCategory data={data} category="platine" />
        <CabaretSponsorCategory data={data} category="or" />
        <CabaretSponsorCategory data={data} category="argent" />
        <p className='uppercase text-black text-center pt-8 pb-4'>Partenaires diffuseurs</p>
        <CabaretSponsorCategory data={data} category="diffuseur" />

    </div>
  )
}

export default CabaretSponsors
