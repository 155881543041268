import React, { useEffect } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { DynamicStaticImage } from '../components/ArtistAtSchool/dynamicStaticImage'
import { getCabaretCandidatBySlug } from '../data/cabaret-data'
import CabaretLayout from '../components/cabaretlayout'
import CabaretCandidats from '../slices/CabaretCandidats'

const cabaretCandidatTemplate = ({ pageContext }) => {
  const { slug } = pageContext
  const candidat = getCabaretCandidatBySlug(slug)
  const name = candidat.name
  const image = candidat.getImageLocalPath
  const date = candidat.date
  const facebook = candidat.facebook
  const instagram = candidat.instagram
  const spotify = candidat.spotify
  const apple = candidat.apple
  const website = candidat.website
  const youtube = candidat.youtube
  const tiktok = candidat.tiktok
  const style = candidat.style
  const youtubeVideoId = candidat.youtubeVideoId

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <CabaretLayout>
        <div className='cabaret-candidat-detail'>
            <div className="!relative lg:max-w-[50%] max-w-[90%] lg:mx-[25%] mx-[5%]">
                <center>
                    <div>
                        {
                            facebook
                              ? <a href={facebook}>
                                <StaticImage
                                    src="../images/cabaret/artists_detail/facebook.png"
                                    alt="Facebook logo"
                                    className="cabaret-candidat-detail-social" />
                            </a>
                              : null
                        }
                        {
                            instagram
                              ? <a href={instagram}>
                                <StaticImage
                                    src="../images/cabaret/artists_detail/instagram.png"
                                    alt="Instagram logo"
                                    className="cabaret-candidat-detail-social ml-4" />
                            </a>
                              : null
                        }
                        {
                            spotify
                              ? <a href={spotify}>
                                <StaticImage
                                    src="../images/cabaret/artists_detail/spotify.png"
                                    alt="Spotify logo"
                                    className="cabaret-candidat-detail-social ml-4" />
                            </a>
                              : null
                        }
                        {
                            apple
                              ? <a href={apple}>
                                <StaticImage
                                    src="../images/cabaret/artists_detail/apple.png"
                                    alt="Apple logo"
                                    className="cabaret-candidat-detail-social ml-4" />
                            </a>
                              : null
                        }
                        {
                            website
                              ? <a href={website}>
                                <StaticImage
                                    src="../images/cabaret/artists_detail/web.png"
                                    alt="Generic web site logo"
                                    className="cabaret-candidat-detail-social ml-4" />
                            </a>
                              : null
                        }
                        {
                            youtube
                              ? <a href={youtube}>
                                <StaticImage
                                    src="../images/cabaret/artists_detail/youtube.png"
                                    alt="Youtube logo"
                                    className="cabaret-candidat-detail-social ml-4" />
                            </a>
                              : null
                        }
                        {
                            tiktok
                              ? <a href={tiktok}>
                                <StaticImage
                                    src="../images/cabaret/artists_detail/tiktok.png"
                                    alt="TikTok logo"
                                    className="cabaret-candidat-detail-social ml-4" />
                            </a>
                              : null
                        }
                    </div>

                    <h2 className='!mb-2 !pb-0'>{name}</h2>
                    <p className="font-poppins lg:text-[16px] text-[12px] text-purple-cabaret mb-8">{style.toUpperCase()}</p>
                    <p className="font-poppins font-bold lg:text-[20px] text-[15px] text-purple-cabaret tracking-[.25em] mb-8">{date.toUpperCase()}</p>

                    <DynamicStaticImage
                        localPath={image}
                        alt={name}
                        className="max-w-[920px] rounded-3xl mb-12" />

                    {/* BIOGRAPHIE */}
                    {/* <div className="mb-16 mt-9">
                        <h3 className="text-[15px] leading-[25px] open-sans my-[1rem] text-justify">{style}</h3>
                    </div> */}

                    <div className="mx-4 pb-12">
                        <div className="!relative pb-[56.25%] max-w-[920px]">
                            <iframe
                                id="festif_a_lecole_bottom_youtube_player"
                                title="Title"
                                className="!absolute top-0 left-0 w-[100%] h-[100%] pb-4"
                                src={`https://www.youtube.com/embed/${youtubeVideoId}`}
                                allowFullScreen="1"
                                referrerPolicy="origin-when-cross-origin" />
                        </div>
                    </div>

                    <div className='cabaret-infos !m-0 p-0'>
                        <div className='flex justify-center items-center'>
                            <form action='/cabaret/billetterie'>
                                <button type='submit'>Billetterie</button>
                            </form>
                        </div>
                    </div>

                </center>

            </div>

            <CabaretCandidats />

        </div>

    </CabaretLayout>
  )
}
export default cabaretCandidatTemplate
